import { Link, NavLink, Outlet } from "react-router-dom";
import {
  faCalendar,
  faCamera,
  faChartLineUp,
  faFlag,
  faHashtag,
  faHeart,
  faMessage,
  faMessageBot,
  faPeople,
  faRadiationAlt,
  faTicket,
  faUserMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";

import { AdminLogin } from "./admin-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { adminRoutes } from "../util/admin-routes";
import { classNames } from "shared/dist/util";
import { useAuthData } from "shared/dist/auth-data";

function SidebarMenuItem({
  to,
  children,
}: React.PropsWithChildren<{ to: string }>): React.JSX.Element {
  return (
    <li className="flex-0">
      <NavLink className={({ isActive }) => (isActive ? "underline" : "")} to={to}>
        {children}
      </NavLink>
    </li>
  );
}

export function AdminMain(): React.JSX.Element {
  const ad = useAuthData();
  if (!ad) {
    return <AdminLogin />;
  }
  return (
    <div className="AdminMain w-screen max-w-screen h-screen max-h-screen flex flex-row items-stretch justify-stretch">
      <div className="flex-0">
        <div className="drawer lg:drawer-open">
          <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content flex flex-col items-center justify-center">
            <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden">
              Open drawer
            </label>
          </div>
          <div className="drawer-side">
            <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
            <ul className="menu p-4 h-full w-80 bg-base-100 text-base-content flex flex-col justify-center items-start">
              <li className="flex-0">
                <Link className="text-xl font-bold" to={"/"}>
                  Simmr Admin
                </Link>
              </li>
              <SidebarMenuItem to={adminRoutes.USERS.buildPath({})}>
                <FontAwesomeIcon icon={faPeople} fixedWidth className="mr-2" />
                Users
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.STATS.buildPath({})}>
                <FontAwesomeIcon icon={faChartLineUp} fixedWidth className="mr-2" />
                Stats
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.NOTIFICATIONS.buildPath({})}>
                <FontAwesomeIcon icon={faMessage} fixedWidth className="mr-2" />
                Notifications
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.EVENTS.buildPath({})}>
                <FontAwesomeIcon icon={faCalendar} fixedWidth className="mr-2" />
                Events
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.PROFILE_GROUPING.buildPath({})}>
                <FontAwesomeIcon icon={faHashtag} fixedWidth className="mr-2" />
                Profile Grouping
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.INVITATIONS.buildPath({})}>
                <FontAwesomeIcon icon={faTicket} fixedWidth className="mr-2" />
                Invite Codes
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.FEATURE_FLAGS.buildPath({})}>
                <FontAwesomeIcon icon={faFlag} fixedWidth className="mr-2" />
                Feature Flags
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.MESSAGE_BLAST.buildPath({})}>
                <FontAwesomeIcon icon={faMessageBot} fixedWidth className="mr-2" />
                User Messaging
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.MEDIA.buildPath({})}>
                <FontAwesomeIcon icon={faCamera} fixedWidth className="mr-2" />
                Photo Review
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.DISCOVERY_STATS.DEBUGGER.buildPath({})}>
                <FontAwesomeIcon icon={faHeart} fixedWidth className="mr-2" />
                Discovery Statistics
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.DISCOVERY_USER.buildPath({})}>
                <FontAwesomeIcon icon={faUserMagnifyingGlass} fixedWidth className="mr-2" />
                User Discovery Data
              </SidebarMenuItem>
              <SidebarMenuItem to={adminRoutes.RELATIONSHIPS.buildPath({})}>
                <FontAwesomeIcon icon={faPeople} fixedWidth className="mr-2" />
                Relationships
              </SidebarMenuItem>
              <div className={classNames("divider")} />
              <SidebarMenuItem to={adminRoutes.NUCLEAR_OPTIONS.buildPath({})}>
                <FontAwesomeIcon icon={faRadiationAlt} fixedWidth className="mr-2" />
                Nuclear Options
              </SidebarMenuItem>
              <div className="flex-1"></div>
              <li className="flex-0">
                <Link className="btn-primary" to={adminRoutes.LOGOUT.path}>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex-1 relative overflow-y-auto max-h-full">
        <Outlet />
      </div>
    </div>
  );
}
